import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";

interface NubenteCommand {
	token: string | null,
	nome: string | null,
	dataNascimento: Date | null,
	nacionalidade: string | null,
	estadoCivil: string | null,
	estadoCivilObject: IBuscarComboboxResult | null,
	genero: string | null,
	generoObject: IBuscarComboboxResult | null,
	tipoIdentidade: string | null,
	tipoIdentidadeObject: IBuscarComboboxResult | null,
	cin: string | null,
	cpf: string | null,
	documentoIdentidade: string | null,
	orgaoExpedidor: string | null,
	profissao: string | null,
	cep: string | null,
	endereco: string | null,
	numero: string | null,
	complemento: string | null,
	bairro: string | null,
	cidade: string | null,
	uf: string | null,
	ufObject: IBuscarComboboxResult | null;
}

const createNubenteDefault = (overrides: Partial<NubenteCommand>): NubenteCommand => ({
	token: null,
	nome: null,
	dataNascimento: null,
	nacionalidade: null,
	estadoCivil: null,
	estadoCivilObject: null,
	genero: null,
	generoObject: null,
	tipoIdentidade: null,
	tipoIdentidadeObject: null,
	cin: null,
	cpf: null,
	documentoIdentidade: null,
	orgaoExpedidor: null,
	profissao: null,
	cep: null,
	endereco: null,
	numero: null,
	complemento: null,
	bairro: null,
	cidade: null,
	uf: null,
	ufObject: null,
	...overrides
});

export {createNubenteDefault};
export type {NubenteCommand};